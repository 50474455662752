<template lang="html">
    <section>
        <reset-password-success />
    </section>
</template>

<script>
// Elements
import ResetPasswordSuccess from '@/components/reset_password/ResetPasswordSuccess';

export default {
    components: {
        ResetPasswordSuccess
    },
    metaInfo: {
        title: 'Reset password'
    }
}
</script>
