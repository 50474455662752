<template lang="html">
    <main-layout>
        <template #content>
            <div class="">
                <loading-overlay />
            </div>
        </template>
    </main-layout>
</template>

<script>
import MainLayout from '@/layouts/MainLayout';
import LoadingOverlay from '@/components/LoadingOverlay';

import { createNamespacedHelpers } from 'vuex';
import { eventBus } from '@/helpers/event-bus/'
import {
    ACTION_WRITER_SOCIAL_LOGIN_VERIFY,
    ACTION_UPDATE_WRITER_DATA,
    LOGIN_RESET_MODULES
} from '@/store/modules/writer/action-types'

const {
    mapActions: mapWriterActions
} = createNamespacedHelpers('writer');

export default {
    components: {
        MainLayout,
        LoadingOverlay
    },
    data: () => ({
        code: null,
        service: null
    }),
    mounted() {
        this.code = this.$route.query.code
        this.service = this.$route.params.service

        this.verifyClient()
            .then((resp) => {
                if (resp.status === 401) {
                    this.$router.push({
                        name: 'confirm-email',
                        query: {
                            token: resp.token,
                            service: this.service
                        }
                    })
                } else {
                    this.applyUser()
                }
            })
            .catch((err) => {
                console.log(err);
            })
    },
    methods: {
        ...mapWriterActions({
            ACTION_WRITER_SOCIAL_LOGIN_VERIFY,
            ACTION_UPDATE_WRITER_DATA,
            LOGIN_RESET_MODULES
        }),
        verifyClient() {
            return this.ACTION_WRITER_SOCIAL_LOGIN_VERIFY({
                code: this.code,
                service: this.service
            })
        },
        async applyUser() {
            const vm = this
            try {
                await this[ACTION_UPDATE_WRITER_DATA]()
                await this[LOGIN_RESET_MODULES]()

                eventBus.$emit('showSnackBar', 'Great success!', 'success');
                this.$router.push({ name: 'account' })
            } catch (err) {
                console.error(err)
                eventBus.$emit('showSnackBar', err, 'error');
                setTimeout(() => {
                    vm.$router.push({ name: 'login' })
                }, 2000)
            }
        }

    }

}
</script>
