<template>
    <main-layout>
        <template #content>
            <section class="authentication authentication-container">
                <div class="authentication-container--login_form">
                    <slot />
                </div>
            </section>
        </template>
    </main-layout>
</template>

<script>
import MainLayout from '@/layouts/MainLayout';

export default {
    name: 'EnteringFormLayout',
    components: {
        MainLayout
    }
}
</script>
