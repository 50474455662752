<template lang="html">
    <entering-form-layout>
        <reset-password-form />
    </entering-form-layout>
</template>

<script>
// Elements
import EnteringFormLayout from '@/layouts/EnteringFormLayout';
import ResetPasswordForm from '@/components/reset_password/ResetPasswordForm';

export default {
    components: {
        ResetPasswordForm,
        EnteringFormLayout
    },
    metaInfo: {
        title: 'Reset Password'
    }
}
</script>
