<template>
    <div class="authentication__form">
        <div>
            <form
                class="signin-section form-block"
                @submit.prevent="validate"
            >
                <div class="form-block__header">
                    <h2 class="form-block__title">
                        Reset password
                    </h2>
                </div>
                <div class="form-block__body">
                    <p class="form-block__description">
                        <span class="lock_icon">
                            <lock />
                        </span>
                        Secure your account with a strong password
                    </p>
                    <div class="password_field">
                        <form-input
                            v-model="$v.password.$model"
                            name="password"
                            legend="Password"
                            prepend-icon="LockIcon"
                            :field.sync="password"
                            :append-icon="showPassword ? 'EyeIcon' : 'EyeOffIcon'"
                            :type="showPassword ? 'text' : 'password'"
                            :errors="validationMessage($v.password)"
                            :is-valid="$v.password.$dirty && !$v.password.$anyError"
                            @clicked="showPassword = !showPassword"
                            @input.native="$v.password.$touch()"
                            @blur="$v.password.$touch(); passwordInFocus = false"
                            @focus="passwordInFocus = true"
                        />
                        <!-- <div class="progress-linear-password">-->
                        <!--    <progress-linear :validate="$v.password.$params.passwordStrengthTest" />-->
                        <!-- </div>-->
                        <div
                            v-if="passwordInFocus && $v.password.$anyError"
                            class="password-validation"
                        >
                            <password-validator-helper :password="password" />
                        </div>
                    </div>
                    <!--                    <form-input-->
                    <!--                        v-model="$v.repeatPassword.$model"-->
                    <!--                        name="password"-->
                    <!--                        legend="Password"-->
                    <!--                        prepend-icon="LockIcon"-->
                    <!--                        :field.sync="repeatPassword"-->
                    <!--                        :append-icon="showPassword ? 'EyeIcon' : 'EyeOffIcon'"-->
                    <!--                        :type="showPassword ? 'text' : 'password'"-->
                    <!--                        :errors="validationMessage($v.repeatPassword)"-->
                    <!--                        :is-valid="$v.repeatPassword.$dirty && !$v.repeatPassword.$anyError"-->
                    <!--                        @clicked="showPassword = !showPassword"-->
                    <!--                        @input.native="$v.repeatPassword.$touch()"-->
                    <!--                        @blur.native="$v.repeatPassword.$touch()"-->
                    <!--                    />-->
                    <button
                        :loading="loading"
                        class="btn-base btn-main button-width-100"
                    >
                        <i class="far fa-save" />Submit
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
// EventBus
import { eventBus } from '@/helpers/event-bus/'

// API
import Api from '@/helpers/api/index.js'

// MDI

import Lock from 'mdi-vue/Lock.vue'

// vuelidate
import { validationMessage } from 'vuelidate-messages'
import { validationMixin } from 'vuelidate'
import passwordValidationMixin from '@/mixins/passwordValidationMixin'
import formValidationMixin from '@/mixins/formValidationMixin'
import { validationRules } from '@/validation/reset-password/Rules'
import { formMessages } from '@/validation/reset-password/Messages'

// elements
import FormInput from '@/components/common/form-elements/FormInput'
import PasswordValidatorHelper from '@/components/PasswordValidatorHelper';

// vuex
import { createNamespacedHelpers } from 'vuex'
import { ACTION_WRITER_RESET_PASSWORD_SET_NEW } from '@/store/modules/writer/action-types'

const { mapActions: mapWriterActions } = createNamespacedHelpers('writer')

export default {
    name: 'ResetPasswordForm',
    components: {
        FormInput,
        Lock,
        PasswordValidatorHelper
    },
    mixins: [
        validationMixin,
        formValidationMixin,
        passwordValidationMixin
    ],
    validations: {
        ...validationRules
    },
    data() {
        return {
            showPassword: false,
            email: '',
            loading: false,
            passwordInFocus: false,
            password: ''
            // repeatPassword: ''
        }
    },
    mounted() {
        if (!this.$route.params.token) {
            eventBus.$emit(
                'showSnackBar',
                'The link has expired, you need to use the reset password form again.',
                'info'
            )
            this.$router.push({ name: 'forgot-password' })
        }
        this.getData()
    },
    methods: {
        ...mapWriterActions([ACTION_WRITER_RESET_PASSWORD_SET_NEW]),
        validationMessage: validationMessage(formMessages),
        getData() {
            Api.post('/api/auth/forgot-password/validate-token', {
                token: this.$route.params.token
            })
                .then((response) => {
                    // set users email for which to update password
                    this.email = response.data
                })
                .catch(() => {
                    this.expired = true
                    eventBus.$emit(
                        'showSnackBar',
                        'The link has expired, you need to use the reset password form again.',
                        'info'
                    )
                    this.$router.push({ name: 'forgot-password' })
                })
        },
        submit() {
            try {
                this.loading = true
                this[ACTION_WRITER_RESET_PASSWORD_SET_NEW]({
                    token: this.$route.params.token,
                    password: this.password,
                    email: this.email
                })
                eventBus.$emit('showSnackBar', 'Your password has been changed', 'success')
                this.$router.push({ name: 'reset-success', params: { token: this.$route.params.token } })
            } catch (err) {
                eventBus.$emit('showSnackBar', err, 'error')
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style scoped lang="scss">

.lock_icon {
    color: $main-color;
}

.progress-linear-password {
    margin-top: 15px;
}

</style>
